import React from "react";

function Footer() {
    return (
        <div className="py-4 text-center text-stone-800">
            &copy; 2024 jovan kouakou all rights reserved
        </div>
    );
};

export default Footer;